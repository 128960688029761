import React from "react";
import { StaticQuery, graphql } from "gatsby";
import styled from "styled-components";
import GravityFormForm from "gatsby-gravityforms-component";
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from "react-google-maps";
import Phone from "../assets/svg/phone.svg";
import Map from "../assets/svg/map.svg";

const ContactStyles = styled.div`
  .page-title {
    padding: 4rem 3rem 5rem;

    h1 {
      font-size: 2.4rem;
      line-height: 3rem;
      border-bottom: 3px solid var(--text);
      padding-bottom: 1.5rem;
      margin-bottom: 3rem;
      text-transform: lowercase;
    }

    p {
      font-size: 1.4rem;
      line-height: 2.1rem;

      &:last-child {
        margin-bottom: 0;
      }
    }

    a {
      display: block;
      margin-top: 2rem;
      text-transform: uppercase;
      color: var(--p-grey);
      font-family: "Roboto Condensed", sans-serif;
      font-weight: 700;
      text-transform: uppercase;
    }
  }

  .contact-form {
    padding: 4rem 3rem;

    ul {
      list-style-type: none;
      margin: 0;
      padding: 0;
    }

    label {
      display: block;
      font-family: "Roboto Condensed", sans-serif;
      font-size: 1.4rem;
      line-height: 1.7rem;
      font-weight: 700;
      margin-bottom: 1rem;
    }

    input {
      width: 100%;
      background: none;
      border: 0;
      height: 2.5rem;
      border-bottom: 1px solid var(--text);
      margin-bottom: 3.5rem;
      box-sizing: border-box;
    }

    textarea {
      height: 180px;
      margin-bottom: 2rem;
      background: var(--light-grey);
      border: 0;
      width: 100%;
      box-sizing: border-box;
    }

    button {
      width: 100%;
      margin-bottom: 0;
    }

    .gfield {
      position: relative;

      .validation_message {
        position: absolute;
        bottom: 1rem;
      }
    }

    .gravityform__field__textarea {
      .validation_message {
        bottom: 0.5rem;
      }
    }

    .gravityform__field--error {
      .validation_message {
        color: red;
      }
    }

    .gravityform__error_inform {
      color: red;
    }
  }

  @media screen and (min-width: 981px) {
    .container {
      max-width: 1220px;
    }

    .page-title {
      padding: 8rem 0 9rem;

      .row {
        .col {
          flex-basis: 20%;

          &:last-child {
            flex-basis: 70%;
            margin-left: 10%;
          }
        }
      }

      h1 {
        font-size: 6rem;
        line-height: 6rem;
        margin-bottom: 8.5rem;
        padding-bottom: 2.5rem;
      }

      h2 {
        font-size: 3rem;
        line-height: 3.8rem;
      }

      p {
        font-size: 1.6rem;
        line-height: 2.1rem;
        margin-top: 0;
      }
    }

    .banner {
      padding: 25rem 3rem;

      .sub-title {
        margin-bottom: 5rem;
      }

      h2 {
        font-size: 3rem;
        line-height: 3.6rem;
      }
    }

    .map-container {
      position: absolute;
      top: 132px;
      left: 0;
      width: 100%;
      height: 100% !important;
      z-index: -1;
    }

    .contact-form,
    .page-title,
    .contact-details {
      position: relative;
      z-index: 2;
      padding: 0;

      .container {
        .row {
          justify-content: flex-end;

          .col {
            flex-basis: 40%;
            background: var(--white);
          }
        }
      }
    }

    .page-title {
      margin-top: 8rem;

      .mobile {
        display: none;
      }

      .col {
        border-top: 1rem solid var(--blue);
        padding: 5rem 6rem 2rem;
      }
    }

    .contact-form {
      .col {
        padding: 0 6rem 4rem;
      }

      ul {
        display: flex;
        flex-wrap: wrap;
        width: calc(100% + 1rem);
        margin-left: -0.5rem;
        margin-right: -0.5rem;

        li {
          flex-basis: calc(50% - 1rem);
          margin: 0 0.5rem;

          &:last-child {
            flex-basis: calc(100% - 1rem);
          }
        }
      }

      .gform_footer {
        text-align: right;

        button {
          display: inline-block;
          width: auto;
        }
      }
    }

    .contact-details {
      margin-bottom: 8rem;

        .container {
          .row {
            .col {
              flex-basis: calc(40% + 12rem);
              display: flex;

              a {
                text-align: center;
                color: var(--white);
                background:  var(--black);
                font-size: 1.6rem;
                line-height: 1.9rem;
                font-family: "Roboto Condensed", sans-serif;
                font-weight: 700;
                padding: 3.5rem 3rem;
                display: flex;
                align-items: center;
                justify-content: center;
                transition: background 0.3s ease;

                svg {
                  margin-right: 1rem;
                }

                &:first-child {
                  border-right: 1px solid var(--white);
                  flex-basis: 40%;
                }

                &:last-child {
                  flex-basis: 60%;
                }

                &:hover {
                  background: var(--blue);
                }
              }
            }
          }
        }
      }
    }
  }
`;

const ContactForm = ({ data }) => {
  const exampleMapStyles = [
    {
      elementType: "geometry",
      stylers: [
        {
          color: "#f5f5f5",
        },
      ],
    },
    {
      elementType: "labels.icon",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#616161",
        },
      ],
    },
    {
      elementType: "labels.text.stroke",
      stylers: [
        {
          color: "#f5f5f5",
        },
      ],
    },
    {
      featureType: "administrative.land_parcel",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#bdbdbd",
        },
      ],
    },
    {
      featureType: "poi",
      elementType: "geometry",
      stylers: [
        {
          color: "#eeeeee",
        },
      ],
    },
    {
      featureType: "poi",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#757575",
        },
      ],
    },
    {
      featureType: "poi.park",
      elementType: "geometry",
      stylers: [
        {
          color: "#e5e5e5",
        },
      ],
    },
    {
      featureType: "poi.park",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#9e9e9e",
        },
      ],
    },
    {
      featureType: "road",
      elementType: "geometry",
      stylers: [
        {
          color: "#ffffff",
        },
      ],
    },
    {
      featureType: "road.arterial",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#757575",
        },
      ],
    },
    {
      featureType: "road.highway",
      elementType: "geometry",
      stylers: [
        {
          color: "#dadada",
        },
      ],
    },
    {
      featureType: "road.highway",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#616161",
        },
      ],
    },
    {
      featureType: "road.local",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#9e9e9e",
        },
      ],
    },
    {
      featureType: "transit.line",
      elementType: "geometry",
      stylers: [
        {
          color: "#e5e5e5",
        },
      ],
    },
    {
      featureType: "transit.station",
      elementType: "geometry",
      stylers: [
        {
          color: "#eeeeee",
        },
      ],
    },
    {
      featureType: "water",
      elementType: "geometry",
      stylers: [
        {
          color: "#c9c9c9",
        },
      ],
    },
    {
      featureType: "water",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#9e9e9e",
        },
      ],
    },
  ];

  let location = data.wpgraphql.page.acfContact.location
    ? data.wpgraphql.page.acfContact.location
    : "44.046138866033544,-121.2916905882241";
  location = location.split(",");
  const lat = parseFloat(location[0]);
  const long = parseFloat(location[1]);

  const MapComponent = withScriptjs(
    withGoogleMap(props => (
      <GoogleMap
        options={{
          styles: exampleMapStyles,
          fullscreenControl: false,
          disableDefaultUI: true,
          disableDoubleClickZoom: true,
        }}
        defaultZoom={15}
        defaultCenter={{ lat: lat, lng: long }}
      >
        <Marker position={{ lat: lat, lng: long }} />
      </GoogleMap>
    ))
  );

  const handleError = ({ values, error, reset }) => {
    const validationErrors = error.data.validation_messages;
    const errorsWrap = document.querySelector(".validation_error");
    const yOffset = -120;
    const y = errorsWrap.getBoundingClientRect().top + window.pageYOffset + yOffset;
    let errors = "";

    Object.values(validationErrors).forEach(error => {
      errors += "<p>" + error + "</p>";
    });

    errorsWrap.innerHTML = errors;

    window.scrollTo({ top: y, behavior: "smooth" });
  };

  const handleSuccess = ({ values, reset, confirmations }) => {
    // console.log(confirmations);
  };

  const formId = parseInt(data.wpgraphql.page.acfContact.form.form);

  return (
    <ContactStyles>
      <div className="page-title">
        <div className="container">
          <div className="row">
            <div className="col">
              <h1 className="mobile">{data.wpgraphql.page.title}</h1>
              <h2>{data.wpgraphql.page.acfContact.form.title}</h2>
              <div
                dangerouslySetInnerHTML={{
                  __html: data.wpgraphql.page.acfContact.form.content,
                }}
              />
              <a href={`tel:+1${data.wpgraphql.page.acfContact.phone}`} className="phone mobile">
                {data.wpgraphql.page.acfContact.phone}
              </a>
              <a
                href={`https://www.google.com/maps/place/${data.wpgraphql.page.acfContact.address}`}
                target="_blank"
                className="address mobile"
              >
                {data.wpgraphql.page.acfContact.address}
              </a>
            </div>
          </div>
        </div>
      </div>
      <MapComponent
        googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${process.env.MAPS_KEY}&v=3.exp&libraries=geometry,drawing,places`}
        loadingElement={<div style={{ height: `100%` }} />}
        containerElement={<div className="map-container" style={{ height: `355px` }} />}
        mapElement={<div style={{ height: `100%` }} />}
      />
      <div className="contact-form">
        <div className="container">
          <div className="row">
            <div className="col">
              <StaticQuery
                query={graphql`
                  query {
                    allGfForm {
                      edges {
                        node {
                          ...GravityFormComponent
                        }
                      }
                    }
                  }
                `}
                render={data => (
                  <GravityFormForm
                    id={formId}
                    formData={data.allGfForm}
                    lambda={`${process.env.BASE_URL}/wp-json/formsubmit/v1/submit/`}
                    successCallback={handleSuccess}
                    errorCallback={handleError}
                  />
                )}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="contact-details desktop">
        <div className="container">
          <div className="row">
            <div className="col">
              <a href={`tel:+1${data.wpgraphql.page.acfContact.phone}`} className="phone">
                <Phone /> {data.wpgraphql.page.acfContact.phone}
              </a>
              <a
                href={`https://www.google.com/maps/place/${data.wpgraphql.page.acfContact.address}`}
                target="_blank"
                className="address"
              >
                <Map /> {data.wpgraphql.page.acfContact.address}
              </a>
            </div>
          </div>
        </div>
      </div>
    </ContactStyles>
  );
};

export default ContactForm;
