import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import Seo from "gatsby-plugin-wpgraphql-seo";
import ContactForm from "../components/contact-form";

const ContactPage = ({ data }) => (
  <Layout>
    <Seo post={data.wpgraphql.page} />

    <ContactForm data={data} />
  </Layout>
);

export default ContactPage;

export const query = graphql`
  query Contact {
    wpgraphql {
      page(id: "contact", idType: URI) {
        id
        seo {
          title
          metaDesc
          focuskw
          metaKeywords
          metaRobotsNoindex
          metaRobotsNofollow
          opengraphTitle
          opengraphDescription
          opengraphImage {
            altText
            sourceUrl
            srcSet
          }
          twitterTitle
          twitterDescription
          twitterImage {
            altText
            sourceUrl
            srcSet
          }
          canonical
          cornerstone
          schema {
            articleType
            pageType
            raw
          }
        }
        acfContact {
          address
          form {
            content
            form
            title
          }
          location
          phone
        }
      }
    }
  }
`;
